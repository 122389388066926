
  .kalio-vue-trix-editor {
    &.kalio-vue-trix-editor--dark {
      trix-editor {
        @apply bg-pv-blue-900 border-2 border-blue-gray-700 focus:border-pv-light-blue
              focus:ring-pv-light-blue #{!important};
      }
    }

    &.kalio-vue-trix-editor--semi-dark {
      trix-editor {
        @apply bg-pv-blue-700 border-2 border-blue-gray-600 focus:border-pv-light-blue
              focus:ring-pv-light-blue #{!important};
      }
    }

    &.kalio-vue-trix-editor--error {
      trix-editor {
        @apply border-red-600 #{!important};
      }
    }

    trix-editor {
      @apply appearance-none rounded-md w-full py-2 px-3 text-sm sm:text-base;

      .attachment__caption {
        @apply hidden;
      }

      pre {
        @apply text-pv-blue-900;
      }

      ul {
        @apply list-disc;
      }

      ol {
        @apply list-decimal;
      }
    }

    trix-toolbar {
      .trix-button-group {
        @apply border-none flex justify-around mb-2;

        &:not(:first-child) {
          @apply ml-4;
        }
      }

      .trix-button-row {
        @apply flex flex-row px-1;
      }

      .trix-button {
        @apply w-8 h-auto max-w-none mx-0.5 border-none rounded-md;

        &--dialog {
          @apply w-auto;
        }

        &--icon::before {
          top: 50%;
          left: 50%;
          @apply w-4 h-4 transform -translate-x-1/2 -translate-y-1/2 opacity-100;
        }

        &:not(:first-child) {
          @apply border-l-0;
        }

        &.trix-button--icon-bold::before {
          background-image: url('../../../assets/images/icons/trix-toolbar/bold-inactive.svg');
        }

        &.trix-button--icon-italic::before {
          background-image: url('../../../assets/images/icons/trix-toolbar/italic-inactive.svg');
        }

        &.trix-button--icon-strike::before {
          background-image: url('../../../assets/images/icons/trix-toolbar/strike-inactive.svg');
        }

        &.trix-button--icon-link::before {
          background-image: url('../../../assets/images/icons/trix-toolbar/link-inactive.svg');
        }

        &.trix-button--icon-heading-1::before {
          background-image: url('../../../assets/images/icons/trix-toolbar/heading-1-inactive.svg');
        }

        &.trix-button--icon-quote::before {
          background-image: url('../../../assets/images/icons/trix-toolbar/quote-inactive.svg');
        }

        &.trix-button--icon-code::before {
          background-image: url('../../../assets/images/icons/trix-toolbar/code-inactive.svg');
        }

        &.trix-button--icon-bullet-list::before {
          background-image: url('../../../assets/images/icons/trix-toolbar/bullet-list-inactive.svg');
        }

        &.trix-button--icon-number-list::before {
          background-image: url('../../../assets/images/icons/trix-toolbar/number-list-inactive.svg');
        }

        &.trix-button--icon-decrease-nesting-level::before {
          background-image: url('../../../assets/images/icons/trix-toolbar/decrease-nesting-level-inactive.svg');
        }

        &.trix-button--icon-increase-nesting-level::before {
          background-image: url('../../../assets/images/icons/trix-toolbar/increase-nesting-level-inactive.svg');
        }

        &.trix-button--icon-attach::before {
          background-image: url('../../../assets/images/icons/trix-toolbar/attach-inactive.svg');
        }

        &.trix-button--icon-undo::before {
          background-image: url('../../../assets/images/icons/trix-toolbar/undo-inactive.svg');
        }

        &.trix-button--icon-redo::before {
          background-image: url('../../../assets/images/icons/trix-toolbar/redo-inactive.svg');
        }

        &.trix-active {
          @apply bg-pv-yellow;

          &.trix-button--icon-bold::before {
            background-image: url('../../../assets/images/icons/trix-toolbar/bold-active.svg');
          }

          &.trix-button--icon-italic::before {
            background-image: url('../../../assets/images/icons/trix-toolbar/italic-active.svg');
          }

          &.trix-button--icon-strike::before {
            background-image: url('../../../assets/images/icons/trix-toolbar/strike-active.svg');
          }

          &.trix-button--icon-link::before {
            background-image: url('../../../assets/images/icons/trix-toolbar/link-active.svg');
          }

          &.trix-button--icon-heading-1::before {
            background-image: url('../../../assets/images/icons/trix-toolbar/heading-1-active.svg');
          }

          &.trix-button--icon-quote::before {
            background-image: url('../../../assets/images/icons/trix-toolbar/quote-active.svg');
          }

          &.trix-button--icon-code::before {
            background-image: url('../../../assets/images/icons/trix-toolbar/code-active.svg');
          }

          &.trix-button--icon-bullet-list::before {
            background-image: url('../../../assets/images/icons/trix-toolbar/bullet-list-active.svg');
          }

          &.trix-button--icon-number-list::before {
            background-image: url('../../../assets/images/icons/trix-toolbar/number-list-active.svg');
          }

          &.trix-button--icon-decrease-nesting-level::before {
            background-image: url('../../../assets/images/icons/trix-toolbar/decrease-nesting-level-active.svg');
          }

          &.trix-button--icon-increase-nesting-level::before {
            background-image: url('../../../assets/images/icons/trix-toolbar/increase-nesting-level-active.svg');
          }

          &.trix-button--icon-attach::before {
            background-image: url('../../../assets/images/icons/trix-toolbar/attach-active.svg');
          }

          &.trix-button--icon-undo::before {
            background-image: url('../../../assets/images/icons/trix-toolbar/undo-active.svg');
          }

          &.trix-button--icon-redo::before {
            background-image: url('../../../assets/images/icons/trix-toolbar/redo-active.svg');
          }
        }
      }

      .trix-input {
        &--dialog {
          @apply mr-0;
        }
      }
    }
  }
