
.dp__theme_dark {
   --dp-background-color: #0F172A;
   --dp-text-color: #F8FAFC;
   --dp-hover-color: #484848;
   --dp-hover-text-color: #131E2F;
   --dp-hover-icon-color: #959595;
   --dp-primary-color: #005cb2;
   --dp-primary-text-color: #ffffff;
   --dp-secondary-color: #a9a9a9;
   --dp-border-color: transparent;
   --dp-menu-border-color: #2d2d2d;
   --dp-border-color-hover: transparent;
   --dp-disabled-color: #737373;
   --dp-scroll-bar-background: #212121;
   --dp-scroll-bar-color: #484848;
   --dp-success-color: #00701a;
   --dp-success-color-disabled: #428f59;
   --dp-icon-color: #959595;
   --dp-danger-color: #e53935;
   --dp-highlight-color: rgba(0, 92, 178, 0.2);
}
